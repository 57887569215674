import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { AnswerColumn, LeaderboardContainer, LeaderboardHeader, LeaderboardRow, RankColumn, RankText, TimeColumn, UserColumn, UserName } from "./styles";
import LazyImage from "../../components/common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants"; 
import { fetchApi } from "../../utils/methods";
import { toast } from "react-toastify";
import { GetLeaderboardQuizDetails } from "../../redux/constants/apiConstants";
import { UserButton } from "../../components/EngagementSection/styles";
import { PaginationCircleNew } from "../../components/Journeys/styles";
import { PaginationIcon } from "../../utils/icons";
import SkeletonLoder from "../../components/common/skeletonLoder";




const QuizLeaderBoard = ({ selectedTab, match }) => {
  const companyDetails = useSelector((state) => state.profileData.userCompany);
  // window.console.log("companyDetails",companyDetails)
  //   const companyId = companyDetails?.id;
  const userDetails = useSelector((state) => state.profileData.user);
  const LoggedInUser = userDetails.uid
   
  const [pageNo, setPageNo] = useState(1);
  const [quizJoinUsers, setQuizJoinUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(6);
  const [loading, setLoading] = useState(false);
  const limit = 20; 
  const totalPages = Math.ceil(totalUsers / limit);


  // window.console.log("totalPages",totalPages)

  const getLeaderboardQuizDetails = async (pageNumber) => {
    const quizId = match.params.id;
    const companyId = companyDetails?.id;

    const apiUrl = `${GetLeaderboardQuizDetails}/${quizId}/${companyId}/${pageNumber}/${limit}`;
    try {
      setLoading(true);
      const res = await fetchApi(apiUrl, "GET");
      // window.console.log("this is page res",res)
      if (res.status === "success") {
        // window.console.log("this is page res",res)
        setQuizJoinUsers(res?.data?.quiz_join_users ); 
        setTotalUsers(res?.data?.total_join_users_count); 
        const calculatedTotalPages = Math.ceil(res?.data?.total_join_users_count / limit);
        // window.console.log("calculatedTotalPages",calculatedTotalPages)
        if (calculatedTotalPages <= 6) {
          setMinLimit(1);
          setMaxLimit(calculatedTotalPages);
        } else {
          setMinLimit(1);
          setMaxLimit(6);
        }

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.error(error);
      toast.error("Failed to fetch leaderboard details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTab === 2) {
      getLeaderboardQuizDetails(pageNo); 
    }
  }, [pageNo, selectedTab]);

  if (selectedTab !== 2) return null;

  const setPagination = (activeIndex) => {
    if (activeIndex !== pageNo) {
      if (totalPages <= 6) {
        setMinLimit(1);
        setMaxLimit(totalPages);
        setPageNo(activeIndex);
      } else {
        if (activeIndex === 1) {
          setPageNo(activeIndex);
          setMaxLimit(6);
          setMinLimit(1);
        } else {
          if (activeIndex + 3 > totalPages) {
            setPageNo(activeIndex);
            setMaxLimit(totalPages);
            setMinLimit(totalPages - 4);
          } else {
            setPageNo(activeIndex);
            setMaxLimit(activeIndex + 3);
            setMinLimit(activeIndex - 1);
          }
        }
      }
    }
  };

  



  const getPaginationCircle = () => {
    let circleButton = [];
    for (let i = minLimit; i <= maxLimit; i++) {
      circleButton.push(
        <PaginationCircleNew key={i} active={i === pageNo} onClick={() => setPagination(i)}>
          <div>{i}</div>
        </PaginationCircleNew>
      );
    }
    return circleButton;
  };

  //   const getBackgroundColor = (index) => {
  //     if(pageNo === 1)
  //     {
  //       if (index === 0) return "#005C87";
  //       if (index === 1) return "rgba(241, 201, 119, 0.15)";
  //       if (index === 2) return "rgba(175, 135, 193, 0.15)";
  //     }
  //     return "#fff";
  //   };
  
  //   const getTextColor = (index) => {
  //     if(pageNo === 1){
  //       return index === 0 ? '#fff' : '#005c87';
  //     }
  //   };


  const getBackgroundColor = (user, index, loggedInUserId) => {
    const isLoggedInUser = user.uid === loggedInUserId;
  
    if (isLoggedInUser) {
      return "#005C87"; 
    }

    if (user.user_rank === 1) return "rgba(118, 171, 120, 0.15)"; 
    if (user.user_rank === 2) return "rgba(241, 201, 119, 0.15)"; 
    if (user.user_rank === 3) return "rgba(175, 135, 193, 0.15)"; 

    return "#fff"; 
  };
  
  const getTextColor = (user, index, loggedInUserId) => {
    window.console.log("hi")
    return user.uid === loggedInUserId ? "#fff" : "#005c87";
  };
  


  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60); 
    const seconds = totalSeconds % 60; 
    if (minutes > 0) {
      return seconds === 0 ? `${minutes} min` : `${minutes} min ${seconds} sec`;
    }
    return `${seconds} sec`; };

  return (
    <LeaderboardContainer>
      <div className="title">Top Leaders</div>
      <LeaderboardHeader>
        <UserColumn>Users</UserColumn>
        <RankColumn>Ranks</RankColumn>
        <AnswerColumn>Answers</AnswerColumn>
        <TimeColumn>Time</TimeColumn>
      </LeaderboardHeader>

      {loading ? (
        Array.from({ length: 10 }).map((_, index) => (
          <LeaderboardRow key={index} backgroundColor="#f0f0f0">
            <UserColumn>
              <SkeletonLoder circle width={48} height={48} />
              <UserName>
                <SkeletonLoder width={100} />
              </UserName>
            </UserColumn>
            <RankColumn>
              <SkeletonLoder width={50} />
            </RankColumn>
            <AnswerColumn>
              <SkeletonLoder width={70} />
            </AnswerColumn>
            <TimeColumn>
              <SkeletonLoder width={60} />
            </TimeColumn>
          </LeaderboardRow>
        ))
      
      ) : (
        quizJoinUsers.map((user, index) => (
          <LeaderboardRow key={user.uid} backgroundColor={getBackgroundColor(user, index,LoggedInUser )} textColor={getTextColor(user,index,LoggedInUser)}  >
            <UserColumn>
              <LazyImage
                style={{ width: "48px", height: "48px", margin: "10px 15px 10px 0px", borderRadius: "48px" }}
                onError={() => window.console.error("Image failed to load")}
                src={`${ImageUrl}/${user.profile_image}`}
              />
              <UserName>{user.name}</UserName>
            </UserColumn>
            <RankColumn>
              {user.user_rank}
              {/* {index + 1 + (pageNo - 1) * limit}  */}
              <RankText>{user.user_rank === 1 ? "st" : user.user_rank === 2 ? "nd" : user.user_rank === 3 ? "rd" : "th"}</RankText>&nbsp;Rank
            </RankColumn>
            <AnswerColumn>{user.correct_answer} Correct</AnswerColumn>
            <TimeColumn>{formatTime(Math.floor(user.total_time))}</TimeColumn>
          </LeaderboardRow>
        ))
      )}

      {totalPages > 1 && (
        <div className="paginationLeaderboard">
          {pageNo > 1 && (
            <UserButton
              width="32px"
              height="32px"
              padding="4px"
              fontSize="14px"
              bgColor="rgba(0, 122, 177, 0.05)"
              onClick={() => setPagination(pageNo - 1)}
            >
              <div style={{transform:"rotate(180deg)",marginLeft:"20px"}}> {PaginationIcon()}</div>
            </UserButton>
          )}
          {getPaginationCircle()}
          {pageNo < totalPages && (
            <UserButton
              width="32px"
              height="32px"
              padding="4px"
              marginLeft="10px"
              fontSize="14px"
              bgColor="rgba(0, 122, 177, 0.05)"
              onClick={() => setPagination(pageNo + 1)}
            >
              <div> {PaginationIcon()} </div>
            </UserButton>
          )}
        </div>
      )}
    </LeaderboardContainer>
  );
};

QuizLeaderBoard.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired, 
};

export default QuizLeaderBoard;